
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Informativa sulla privacy resa ai sensi dell’art. 13 del d.lg. n. 196/2003 (Codice in materia di protezione dei dati personali) che descrive le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.`}</p>
    <p>{`L’informativa è resa solo per `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://www.emanueleperuffo.com",
          "target": "_blank",
          "rel": "noopener"
        }}>{`www.emanueleperuffo.com`}</a></strong>{` e non anche per altri siti eventualmente consultati dall’utente tramite link.`}</p>
    <h2>{`Titolare del trattamento`}</h2>
    <p>{`Emanuele Peruffo`}<br parentName="p"></br>{`
`}{`Via Padova 31, Milano, Italia`}</p>
    {
      /* CF: PRFMNL87D07F464Z
      P.IVA: 03904670241 */
    }
    <h2>{`Dati trattati`}</h2>
    <h3>{`Dati di navigazione`}</h3>
    <p>{`I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.`}</p>
    <p>{`Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.`}</p>
    <p>{`In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.`}</p>
    <p>{`Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito.`}</p>
    <h3>{`Dati forniti dall’utente`}</h3>
    <p>{`L’invio facoltativo, esplicito e volontario di dati attraverso l’apposito modulo della pagina `}<em parentName="p">{`Contatti`}</em>{` comporta l’invio di un’email contenente i dati specificati. Questa email verrà conservata dal server di posta fino ad eliminazione da parte del destinatario.`}</p>
    <p>{`L’invio facoltativo, esplicito e volontario di email agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Queste email verranno conservate dal server di posta fino ad eliminazione da parte del destinatario.`}</p>
    <h2>{`Modalità del trattamento`}</h2>
    <p>{`I dati raccolti sono processati con strumenti automatizzati, sono conservati in formato elettronico presso i server interni dell’azienda e/o eventuali server virtuali in cloud e non sono resi noti a terzi se non in forza di leggi dello Stato.`}</p>
    <p>{`Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.`}</p>
    <p>{`Possono avere accesso ai dati il titolare del trattamento, il rappresentante, i responsabili e gli incaricati del trattamento se nominati ed eventuali tecnici e/o consulenti preposti alla gestione del sito web e del sistema informativo aziendale.`}</p>
    <p>{`I dati non sono divulgati a terzi.`}</p>
    <h2>{`Diritti dell’interessato`}</h2>
    <ol>
      <li parentName="ol">{`L’interessato ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.`}</li>
      <li parentName="ol">{`L’interessato ha diritto di ottenere l’indicazione:`}
        <ol parentName="li">
          <li parentName="ol">{`dell’origine dei dati personali;`}</li>
          <li parentName="ol">{`delle finalità e modalità del trattamento;`}</li>
          <li parentName="ol">{`della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici;`}</li>
          <li parentName="ol">{`degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’articolo 5, comma 2;`}</li>
          <li parentName="ol">{`dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`L’interessato ha diritto di ottenere:`}
        <ol parentName="li">
          <li parentName="ol">{`l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati;`}</li>
          <li parentName="ol">{`la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;`}</li>
          <li parentName="ol">{`l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`L’interessato ha diritto di opporsi, in tutto o in parte:`}
        <ol parentName="li">
          <li parentName="ol">{`per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;`}</li>
          <li parentName="ol">{`al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Cookie`}</h2>
    <p>{`I cookie sono piccoli file di testo salvati dal tuo browser.`}</p>
    <p>{`Questo sito usa cookie e tecnologie simili, anche di terze parti, per migliorare l’esperienza di navigazione e per fini statistici. La scelta di non accettare cookie può limitare il funzionamento del sito.`}</p>
    <h3>{`Cookie installati dal sito`}</h3>
    <ul>
      <li parentName="ul">{`locale: Usato per ricordare l’impostazione della lingua.`}</li>
    </ul>
    <h3>{`Cookie di Google Analytics`}</h3>
    <p>{`Google Analytics è un servizio che permette di tracciare statistiche sul traffico del sito.`}</p>
    <p>{`Questo sito usa Google Analytics grazie alla sua libreria `}<em parentName="p">{`ga.js`}</em>{`.`}</p>
    <p>{`Per funzionare, Google Analytics potrebbe salvare uno dei seguenti cookie:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`utma: Usato per contraddistinguere utenti e sessioni. Il cookie viene creato quando la libreria javascript viene eseguita e non esiste ancora nessun cookie `}</strong>{`utma. Il cookie viene aggiornato ad ogni trasmissione di dati verso Google Analytics.`}</li>
      <li parentName="ul">{`__utmt: Usato per regolare la frequenza delle richieste.`}</li>
      <li parentName="ul"><strong parentName="li">{`utmb: Usato per riconoscere nuove sessioni/visite. Il cookie viene creato quando la libreria javascript viene eseguita e non esiste ancora nessun cookie `}</strong>{`utmb. Il cookie viene aggiornato ad ogni trasmissione di dati verso Google Analytics.`}</li>
      <li parentName="ul"><strong parentName="li">{`utmc: Non usato in ga.js. Viene impostato per assicurare l’interoperabilità con urchin.js. Storicamente, questo cookie operava in combinazione con il cookie `}</strong>{`utmb per determinare se l’utente fosse in una nuova sessione/visita.`}</li>
      <li parentName="ul">{`__utmz: Salva la fonte di traffico o la campagna che descrive come l’utente ha raggiunto il sito. Il cookie viene creato quando la libreria javascript viene eseguita e viene aggiornato ad ogni trasmissione di dati verso Google Analytics.`}</li>
      <li parentName="ul">{`__utmv: Usato per salvare dati variabili personalizzati a livello di visitatore. Questo cookie viene creato quando uno sviluppatore usa il metodo _setCustomVar con una variabile personalizzata a livello di visitatore. Questo cookie viene inoltre usato per il metodo _setVar, ora deprecato. Il cookie viene aggiornato ad ogni trasmissione di dati verso Google Analytics.`}</li>
      <li parentName="ul">{`__utmx: Usato per determinare l’inclusione di un utente in un esperimento.`}</li>
      <li parentName="ul">{`__utmxx: Usato per determinare la scadenza degli esperimenti dove l’utente è stato incluso.`}</li>
      <li parentName="ul">{`_gaexp: Usato per determinare l’inclusione di un utente in un esperimento e per determinare la scadenza degli esperimenti dove l’utente è stato incluso.`}</li>
    </ul>
    <p>{`Per maggiori informazioni, consulta la `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy?hl=it",
        "target": "_blank",
        "rel": "noopener"
      }}>{`privacy policy di Google Analytics`}</a>{` e il loro `}<a parentName="p" {...{
        "href": "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
        "target": "_blank",
        "rel": "noopener"
      }}>{`utilizzo dei cookie`}</a>{`.`}</p>
    <h3>{`Gestione dei cookie`}</h3>
    <h4>{`Cookie del browser`}</h4>
    <p>{`Puoi consentire o negare la registrazione dei cookie modificando le impostazioni del tuo browser:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Internet Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/chrome/answer/95647?hl=it",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Google Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/it/kb/Gestione%20dei%20cookie",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Mozilla Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Apple Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://help.opera.com/Windows/10.00/it/cookies.html",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Opera`}</a></li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    